import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CartTableRowDivider from './CartTableRowDivider';
import styles from './styles.module.css';
import ActivityPricing from '@components/activity/ActivityPricing';
import { useCart } from '@contexts/CartContext';
import { App, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

function CartTableRow({ item = {} }) {
    const { deleting, deleteItem } = useCart();
    const { message } = App.useApp();

    function removeCartItem(cartItem) {
        if (!window.confirm(`移除 "${cartItem.activity.name}"?`)) {
            return;
        }
        deleteItem(cartItem.id)
            .then((data) => {
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                message?.error(msg);
            });
    }

    return (
        <>
            <tr>
                <td className={styles.description_td}>
                    <div className={styles.description}>
                        <div>
                            <span className={styles.product_name} title={'打开活动页面'}>
                                {item.activity.name}
                            </span>
                            &nbsp;
                            <Link to={`/dashboard/activities/${item.activity.id}`}>
                                <ExportOutlined
                                    style={{ cursor: 'pointer' }}
                                    name="external alternate"
                                    title={item.activity.name}
                                />
                            </Link>
                        </div>

                        <div className={styles.extra_container}>
                            {item.bookingInfo.segmented && (
                                <span className={styles.tag_container}>{item?.bookingInfo?.segment.value}</span>
                            )}
                            {item.bookingInfo.upgraded && (
                                <span className={styles.tag_container}>{item?.bookingInfo?.variant.nameZH}</span>
                            )}
                        </div>
                    </div>
                </td>
                <td className={styles.pricing_td}>
                    <div className={styles.group_container}>
                        <ActivityPricing pricing={item.effectPricing} />
                    </div>
                </td>
                <td>
                    <Button
                        type="link"
                        size="small"
                        danger
                        loading={deleting}
                        onClick={() => removeCartItem(item)}
                        title="移除"
                    >
                        移除
                    </Button>
                </td>
            </tr>
            <CartTableRowDivider />
        </>
    );
}

CartTableRow.propTypes = {
    item: PropTypes.object.isRequired,
};

export default CartTableRow;
