// Role
export const AgentRole = {
    Manager: 1,
    Operator: 2,
};

export const GetRoleName = (value) => {
    switch (value) {
        case AgentRole.Manager:
            return '主账号';
        case AgentRole.Operator:
            return '子账号';
        default:
            return '未知账号';
    }
};

// OrderStatus
export const OrderStatus = {
    Received: 1, // 已接收
    Sent: 2, // 已发送邮件给供应商
    Confirmed: 3, // 供应商确认完成
    Cancelled: 4, // 订单取消
};

export const OrderStatusName = (value) => {
    switch (value) {
        case OrderStatus.Received:
            return '已接收';
        case OrderStatus.Sent:
            return '已发送';
        case OrderStatus.Confirmed:
            return '完成确认';
        case OrderStatus.Cancelled:
            return '订单取消';
        default:
            return 'Unknown';
    }
};

// PaymentMethod
export const PaymentMethod = {
    BankTransfer: 1, //银行转账
    CreditCard: 2, // "信用卡"
};

export const PaymentMethodName = (value) => {
    switch (value) {
        case PaymentMethod.BankTransfer:
            return '银行转账';
        case PaymentMethod.CreditCard:
            return '信用卡';
        default:
            return '未知';
    }
};

//CAT
export const CAT = {
    Newsletter: 1,
    FAQ: 2,
    FAQ4Agency: 3,
};

//Visa cat
export const Visa = {
    Tourist: 'tourist',
    WorkingHoliday: 'working_holiday',
};

// Accommodation
export const CATAccom = {
    Hotel: 'hotel',
    House: 'house',
    Camp: 'camping',
    Package: 'package',
    Apartment: 'apartment',
};

export const CATAccomParse = (cat) => {
    switch (cat) {
        case 1:
            return CATAccom.Hotel;
        case 2:
            return CATAccom.House;
        case 3:
            return CATAccom.Camp;
        case 4:
            return CATAccom.Package;
        case 5:
            return CATAccom.Apartment;
        default:
            return 'Uknown';
    }
};

export const AccomCATName = (value) => {
    switch (value) {
        case (1, CATAccom.Hotel):
            return '酒店';
        case (2, CATAccom.House):
            return '豪华别墅';
        case (3, CATAccom.Camp):
            return '露营体验';
        case (4, CATAccom.Package):
            return '住宿套餐';
        case (5, CATAccom.Apartment):
            return '公寓';
        default:
            return '';
    }
};

//selectors
export const Selector = {
    Sydney: 'sydney',
    Melbourne: 'melbourne',
    Brisbane: 'brisbane',
    Cairns: 'cairns',
    GoldCoast: 'gold coast',
    Adelaide: 'adelaide',
    Perth: 'perth',
    Tasmania: 'tasmania',
    HamiltonIsland: 'hamilton island',
    Uluru: 'uluru',
    Darwin: 'darwin',
    Canberra: 'canberra',
    Auckland: 'auckland',
    NewZealand: 'new zealand',
    AustralianTourItinerary: 'australian tour itinerary',
    Team: 'team',
    Package: 'package',
    Transfers: 'transfers',
    Cruises: 'cruises',
    Cars: 'cars',
    NSW: 'NSW',
    VIC: 'VIC',
    QLD: 'QLD',
    SA: 'SA',
    WA: 'WA',
    Others: 'others',
    OtherCity: 'other city',
    OtherExperience: 'other experience',
};

export const SelectorParser = (sel) => {
    switch (sel) {
        case Selector.Sydney:
            return '悉尼';
        case Selector.NSW:
            return '悉尼周边';
        case Selector.Melbourne:
            return '墨尔本';
        case Selector.VIC:
            return '墨尔本周边';
        case Selector.Brisbane:
            return '布里斯班';
        case Selector.QLD:
            return '布里斯班周边';
        case Selector.GoldCoast:
            return '黄金海岸';
        case Selector.Cairns:
            return '凯恩斯';
        case Selector.Adelaide:
            return '阿德莱德';
        case Selector.SA:
            return '阿德莱德周边';
        case Selector.Perth:
            return '佩斯';
        case Selector.WA:
            return '佩斯周边';
        case Selector.Canberra:
            return '堪培拉';
        case Selector.Tasmania:
            return '塔斯马尼亚';
        case Selector.HamiltonIsland:
            return '汉密尔顿岛';
        case Selector.Uluru:
            return '乌鲁鲁';
        case Selector.Darwin:
            return '达尔文';
        case Selector.Auckland:
            return '奥克兰';
        case Selector.NewZealand:
            return '新西兰';
        case Selector.OtherCity:
            return '其他城市';

        case Selector.Cars:
            return '包车旅游';
        case Selector.Team:
            return '当地参团';
        case Selector.Package:
            return '当地包团';
        case Selector.Transfers:
            return '机场接送';
        case Selector.Cruises:
            return '南太平洋邮轮';
        case Selector.AustralianTourItinerary:
            return '定制参考';
        case Selector.OtherExperience:
            return '其他体验';
        default:
            '';
    }
};

export const SelectorCityOptions = [
    { name: '悉尼', value: Selector.Sydney },
    { name: '悉尼周边', value: Selector.NSW },
    { name: '墨尔本', value: Selector.Melbourne },
    { name: '墨尔本周边', value: Selector.VIC },
    { name: '布里斯班', value: Selector.Brisbane },
    { name: '黄金海岸', value: Selector.GoldCoast },
    { name: '布里斯班周边', value: Selector.QLD },
    { name: '凯恩斯', value: Selector.Cairns },
    { name: '阿德莱德', value: Selector.Adelaide },
    { name: '阿德莱德周边', value: Selector.SA },
    { name: '佩斯', value: Selector.Perth },
    { name: '佩斯周边', value: Selector.WA },
    { name: '堪培拉', value: Selector.Canberra },
    { name: '塔斯马尼亚', value: Selector.Tasmania },
    { name: '汉密尔顿岛', value: Selector.HamiltonIsland },
    { name: '乌鲁鲁', value: Selector.Uluru },
    { name: '达尔文', value: Selector.Darwin },
    { name: '奥克兰', value: Selector.Auckland },
    { name: '新西兰', value: Selector.NewZealand },
    { name: '其他城市', value: Selector.OtherCity },
];

export const SelectorCityOnlyOptions = [
    { name: '悉尼', value: Selector.Sydney },
    { name: '悉尼周边', value: Selector.NSW },
    { name: '墨尔本', value: Selector.Melbourne },
    { name: '墨尔本周边', value: Selector.VIC },
    { name: '布里斯班', value: Selector.Brisbane },
    { name: '黄金海岸', value: Selector.GoldCoast },
    { name: '布里斯班周边', value: Selector.QLD },
    { name: '凯恩斯', value: Selector.Cairns },
    { name: '阿德莱德', value: Selector.Adelaide },
    { name: '阿德莱德周边', value: Selector.SA },
    { name: '佩斯', value: Selector.Perth },
    { name: '佩斯周边', value: Selector.WA },
    { name: '堪培拉', value: Selector.Canberra },
    { name: '塔斯马尼亚', value: Selector.Tasmania },
    { name: '汉密尔顿岛', value: Selector.HamiltonIsland },
    { name: '乌鲁鲁', value: Selector.Uluru },
    { name: '达尔文', value: Selector.Darwin },
    { name: '奥克兰', value: Selector.Auckland },
    { name: '新西兰', value: Selector.NewZealand },
];

const SelectorOptions = [
    { name: SelectorParser(Selector.Cars), value: Selector.Cars },
    { name: SelectorParser(Selector.Team), value: Selector.Team },
    { name: SelectorParser(Selector.Package), value: Selector.Package },
    { name: SelectorParser(Selector.Cruises), value: Selector.Cruises },
    { name: SelectorParser(Selector.AustralianTourItinerary), value: Selector.AustralianTourItinerary },
    { name: SelectorParser(Selector.OtherExperience), value: Selector.OtherExperience },
    ...SelectorCityOptions,
];

export const DestinationName = (value) => {
    const opt = SelectorOptions.find((item) => item.value == value);
    if (opt) {
        return opt.name;
    } else {
        value;
    }
};

export const ActivityHotTagOptions = [
    { name: SelectorParser(Selector.Sydney), value: Selector.Sydney },
    { name: SelectorParser(Selector.Melbourne), value: Selector.Melbourne },
    { name: SelectorParser(Selector.Brisbane), value: Selector.Brisbane },
    { name: SelectorParser(Selector.GoldCoast), value: Selector.GoldCoast },
    { name: SelectorParser(Selector.Cairns), value: Selector.Cairns },
    { name: SelectorParser(Selector.Tasmania), value: Selector.Tasmania },
    { name: SelectorParser(Selector.HamiltonIsland), value: Selector.HamiltonIsland },
    { name: SelectorParser(Selector.Uluru), value: Selector.Uluru },
    { name: SelectorParser(Selector.Perth), value: Selector.Perth },
    { name: SelectorParser(Selector.Adelaide), value: Selector.Adelaide },
    { name: SelectorParser(Selector.Canberra), value: Selector.Canberra },
    { name: SelectorParser(Selector.Auckland), value: Selector.Auckland },
    { name: SelectorParser(Selector.NewZealand), value: Selector.NewZealand },
];

export const ActivityHotCityOptions = [
    { name: SelectorParser(Selector.Sydney), value: Selector.Sydney },
    { name: SelectorParser(Selector.Melbourne), value: Selector.Melbourne },
    { name: SelectorParser(Selector.Brisbane), value: Selector.Brisbane },
    { name: SelectorParser(Selector.GoldCoast), value: Selector.GoldCoast },
    { name: SelectorParser(Selector.Cairns), value: Selector.Cairns },
    { name: SelectorParser(Selector.Tasmania), value: Selector.Tasmania },
    { name: SelectorParser(Selector.HamiltonIsland), value: Selector.HamiltonIsland },
    { name: SelectorParser(Selector.Uluru), value: Selector.Uluru },
    { name: SelectorParser(Selector.Perth), value: Selector.Perth },
    { name: SelectorParser(Selector.Adelaide), value: Selector.Adelaide },
    { name: SelectorParser(Selector.Canberra), value: Selector.Canberra },
    { name: SelectorParser(Selector.Auckland), value: Selector.Auckland },
    { name: SelectorParser(Selector.NewZealand), value: Selector.NewZealand },
];

export const AccommodationSelectorOptions = [
    { name: SelectorParser(Selector.Sydney), value: Selector.Sydney },
    { name: SelectorParser(Selector.Melbourne), value: Selector.Melbourne },
    { name: SelectorParser(Selector.Brisbane), value: Selector.Brisbane },
    { name: SelectorParser(Selector.GoldCoast), value: Selector.GoldCoast },
    { name: SelectorParser(Selector.Cairns), value: Selector.Cairns },
    { name: SelectorParser(Selector.Tasmania), value: Selector.Tasmania },
    { name: SelectorParser(Selector.HamiltonIsland), value: Selector.HamiltonIsland },
    { name: SelectorParser(Selector.Uluru), value: Selector.Uluru },
    { name: SelectorParser(Selector.Perth), value: Selector.Perth },
    { name: SelectorParser(Selector.Adelaide), value: Selector.Adelaide },
    { name: SelectorParser(Selector.Canberra), value: Selector.Canberra },
    { name: SelectorParser(Selector.Auckland), value: Selector.Auckland },
    { name: SelectorParser(Selector.NewZealand), value: Selector.NewZealand },
    { name: SelectorParser(Selector.OtherCity), value: Selector.OtherCity },
];

export const FlightOrderStatus = {
    AwaitingPayment: 0,
    IssuingTickets: 1,
    AbnormalTicketIssuanceRequest: 2,
    Upcoming: 3,
    Canceled: 4,
    Complete: 5,
};

export const FlightOrderStatusName = (statusCode) => {
    switch (statusCode) {
        case FlightOrderStatus.AwaitingPayment:
            return '未支付';
        case FlightOrderStatus.IssuingTickets:
            return '已支付待出票';
        case FlightOrderStatus.AbnormalTicketIssuanceRequest:
            return '出票请求异常';
        case FlightOrderStatus.Upcoming:
            return '出票成功待出行';
        case FlightOrderStatus.Canceled:
            return '关闭(超时&手动)';
        case FlightOrderStatus.Complete:
            return '订单完成';
        default:
            return '未知状态';
    }
};

export const Currency = {
    CNY: 'CNY',
    AUD: 'AUD',
};

export const ActivityDuration = {
    LessOneHour: 1,
    OneToFourHour: 2,
    FourToOneDay: 3,
    MoreThanOneDay: 4,
};

export const ActivityDurationParse = (duration) => {
    switch (duration) {
        case ActivityDuration.LessOneHour:
            return '小于1小时';
        case ActivityDuration.OneToFourHour:
            return '1到4小时';
        case ActivityDuration.FourToOneDay:
            return '4小时到1天';
        case ActivityDuration.MoreThanOneDay:
            return '超过1天';
    }
};

export const ActivityInfoRole = {
    Include: 1,
    Exclude: 2,
    Notice: 3,
    ChangePolicy: 4,
};

export const ActivityInfoRoleParse = (role) => {
    switch (role) {
        case ActivityInfoRole.Include:
            return '费用包含';
        case ActivityInfoRole.Exclude:
            return '费用不含';
        case ActivityInfoRole.Notice:
            return '注意事项';
        case ActivityInfoRole.ChangePolicy:
            return '退改规则';
    }
};

export const BillingType = {
    Month: 1, //1 月结：下级用户先进行消费，自定义结算日进行结算，不限制额度
    Credit: 2, //2 授信：就是授信给下级用户设置一定的额度，到自定义结款日，进行结算
    Prepay: 3, //3 预付：下级用户在您这充值一定的额度，他花自己充值的额度，花完充值，没有固定的结算日
    Spot: 8, //8 现付
};

export const BillingTypeParse = (code) => {
    switch (code) {
        case BillingType.Month:
            return '月结';
        case BillingType.Credit:
            return '授信';
        case BillingType.Prepay:
            return '预付';
        case BillingType.Spot:
            return '现付';
        default:
            break;
    }
};

export const BedTypeCode = {
    Double: 1,
    Futon: 2,
    King: 3,
    MurphyBed: 4,
    Queen: 5,
    SofaBed: 6,
    TatamiMats: 7,
    Twin: 8,
    Single: 9,
    Full: 10,
    RunOfTheHouse: 11,
    DormBed: 12,
    WaterBed: 13,
    SuperKing: 14,
};

export const BedTypeParse = (code) => {
    switch (code) {
        case BedTypeCode.Double:
            return '双人床';
        case BedTypeCode.Futon:
            return '日本床垫';
        case BedTypeCode.King:
            return '特大床';
        case BedTypeCode.MurphyBed:
            return '折叠床';
        case BedTypeCode.Queen:
            return '大床';
        case BedTypeCode.SofaBed:
            return '沙发床';
        case BedTypeCode.TatamiMats:
            return '榻榻米';
        case BedTypeCode.Twin:
            return '双床';
        case BedTypeCode.Single:
            return '单床';
        case BedTypeCode.Full:
            return '全尺寸床';
        case BedTypeCode.RunOfTheHouse:
            return '随机房型';
        case BedTypeCode.DormBed:
            return '宿舍床';
        case BedTypeCode.WaterBed:
            return '水床';
        case BedTypeCode.SuperKing:
            return '超级加大双人床';
        default:
            break;
    }
};

export const SabreHotelRateSource = {
    GDS: '100',
    Expedia: '110',
    Bedsonline: '112', //HotelBeds
    Booking: '113',
};

export function SabreHotelRateSourceText(code) {
    const convert = (code) => {
        switch (code) {
            case SabreHotelRateSource.GDS:
                return 'S';
            case SabreHotelRateSource.Expedia:
                return 'E';
            case SabreHotelRateSource.Bedsonline:
                return 'H';
            case SabreHotelRateSource.Booking:
                return 'B';
            default:
                return '未知';
        }
    };

    // if code is array
    if (Array.isArray(code)) {
        return code.map((item) => {
            return convert(item);
        });
    } else if (typeof code === 'string') {
        return convert(code);
    }
}

export const TicketingMsg = '出票时间为当天澳大利亚时间晚上11:00。';
