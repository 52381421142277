import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Drawer.module.css';
import { useLocation } from 'react-router';

function Drawer({ active = false, setPropsActive = () => {}, children }) {
    const [muskClasses, setMuskClasses] = useState(styles.musk);
    const [drawerClasses, setDrawerClasses] = useState(styles.drawer);

    const location = useLocation();

    //snap drawer when pathname changed
    useEffect(() => {
        if (active) {
            snapDrawer();
        }
    }, [location]);

    useLayoutEffect(() => {
        // console.log('active changed', active);
        if (active) {
            openDrawer();
        } else {
        }
    }, [active]);

    const openDrawer = () => {
        setMuskClasses(`${styles.musk} ${styles['musk-active']}`);
        setDrawerClasses(`${styles.drawer} ${styles['drawer-active']}`);
        setPropsActive(true);
    };

    const closeDrawer = () => {
        setMuskClasses(styles.musk);
        setDrawerClasses(styles.drawer);
        setPropsActive(false);
    };

    const snapDrawer = () => {
        setMuskClasses(`${styles.musk} ${styles['transition-snap']}`);
        setDrawerClasses(`${styles.drawer} ${styles['transition-snap']}`);
        setPropsActive(false);
    };

    return (
        <>
            <div className={muskClasses} onClick={closeDrawer}></div>
            <div className={drawerClasses}>
                <div className={styles.content}>{children}</div>
            </div>
        </>
    );
}

Drawer.propTypes = {
    active: PropTypes.bool,
    setPropsActive: PropTypes.func,
};

export default Drawer;
