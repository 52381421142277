import React from 'react';
import styles from './styles.module.css';

function CartTableRowDivider() {
    return (
        <tr>
            <td className={styles.item_divider_container} colSpan="5">
                <div className={styles.top}></div>
                <div className={styles.bottom}></div>
            </td>
        </tr>
    );
}

export default CartTableRowDivider;
