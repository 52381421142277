import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeMessageAt } from '@features/messages/messagesSlice';
import { Alert } from 'antd';

function MessageBoard() {
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.messages.data);

    return (
        <div>
            {messages.length > 0 && (
                <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {messages.map((item, index) => {
                        if (item.content) {
                            return (
                                <Alert
                                    key={index}
                                    type={item.type}
                                    description={item.content}
                                    closable
                                    onClose={() => dispatch(removeMessageAt({ index: index }))}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            )}
        </div>
    );
}

export default MessageBoard;
