import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { App, Button, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import useActivityOrderUpdate from './useActivityOrderUpdate';

const UserDefinedEdit = ({ orderID, userDefined, callback = () => {} }) => {
    const [open, setOpen] = useState(false);
    const [newUserDefined, setNewUserDefined] = useState();
    const { submitting, handleUpdateUserDefined, abort } = useActivityOrderUpdate();
    const { message } = App.useApp();

    useEffect(() => {
        if (open) {
            setNewUserDefined(userDefined);
        } else {
            setNewUserDefined();
        }
        return () => {
            abort();
        };
    }, [userDefined, open]);

    const handleSubmit = () => {
        console.log(newUserDefined);
        handleUpdateUserDefined(orderID, newUserDefined)
            .then((data) => {
                setOpen(false);
                callback(data);
                message?.success(data.reply);
            })
            .catch((error) => {
                console.error(error);
                message?.error(error.message);
            });
    };

    return (
        <div>
            <span>{userDefined.slice(0, 8)}</span>
            <Button type="link" size="small" onClick={() => setOpen((prev) => !prev)} icon={<EditOutlined />} />
            <Modal
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={handleSubmit}
                okButtonProps={{
                    loading: submitting,
                }}
                cancelButtonProps={{
                    disabled: submitting,
                }}
            >
                <h3>自定义名称</h3>
                <Input
                    placeholder="自定义名称（团号）"
                    value={newUserDefined}
                    onChange={(e) => setNewUserDefined(e.target.value)}
                    autoFocus={true}
                    autoComplete="new-password"
                />
            </Modal>
        </div>
    );
};

UserDefinedEdit.propTypes = {
    orderID: PropTypes.string.isRequired,
    userDefined: PropTypes.string,
    callback: PropTypes.func,
};

export default UserDefinedEdit;
