import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as config from '@config';
import { GetToken } from '@utils/storage';
import { th } from 'date-fns/locale';

function useActivityOrderDelete() {
    const [deleting, setDeleting] = useState(false);
    const abortControllerRef = useRef(null);

    const handleDelete = useCallback(async (id) => {
        setDeleting(true);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/order/${id}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while updating the order');
            }
            return await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error updating order:', error);
            }
            throw error;
        } finally {
            setDeleting(false);
        }
    }, []);

    handleDelete.propTypes = {
        id: PropTypes.number.isRequired,
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { deleting, handleDelete, abort };
}

export default useActivityOrderDelete;
