import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/BackBar.module.css';
import { useNavigate } from 'react-router';

function BackBar({ backURL, ...rest }) {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (backURL) {
            navigate(backURL);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className={styles.container} {...rest}>
            {/* <Button onClick={handleOnClick}>&#9664; Back</Button> */}
            <button className="secondary_btn greyscale" onClick={handleOnClick}>
                &#9664; Back
            </button>
        </div>
    );
}

BackBar.propTypes = {
    backURL: PropTypes.string,
};

export default BackBar;
