import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useActivityOrders, { defaultQueryParams } from './useActivityOrders';
import { format } from 'date-fns';
import { Table, Checkbox, Space, Button, App } from 'antd';
import { Link } from 'react-router-dom';
import UserDefinedEdit from './UserDefinedEdit';
import useActivityorderDelete from './useActivityOrderDelete';
import { GetUserInfo } from '@utils/storage';
import { AgentRole } from '@utils/constants';
import { SetOrdersQuery, GetOrdersQuery } from './storage';

export const defaultQueryReq = {
    agencyScope: false,
    ...defaultQueryParams,
    pageSize: 3,
};

function ActivityOrdersTable(props) {
    const { fetching, orders, total, fetchOrders, abort } = useActivityOrders();
    const { deleting, handleDelete, abort: abortDelete } = useActivityorderDelete();
    const [req, setReq] = useState(GetOrdersQuery());
    const { message } = App.useApp();

    useEffect(() => {
        fetchData(req);
        return () => {
            abort();
            abortDelete();
        };
    }, [req]);

    const fetchData = async (params = {}) => {
        try {
            const result = await fetchOrders(params);
            SetOrdersQuery(params);
        } catch (error) {
            console.error('Failed to fetch orders:', error);
            message?.error(error.message);
        }
    };

    const handleOnDelete = (id) => {
        console.log('handleDelete', id);
        if (confirm('Are you sure you want to delete this order?') !== true) return;
        handleDelete(id)
            .then((data) => {
                fetchData(req);
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                }
            })
            .catch((error) => {
                console.error('Error deleting order:', error);
                message?.error(error.message);
            });
    };

    const columns = [
        {
            title: '团号',
            dataIndex: 'userDefined',
            key: 'userDefined',
            render: (text, record) => {
                const orderID = record.id;
                return <UserDefinedEdit orderID={orderID} userDefined={text} callback={() => fetchData(req)} />;
            },
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            render: (text) => format(new Date(text), 'yyyy-MM-dd HH:mm'),
        },
        {
            title: '操作员',
            dataIndex: ['agent', 'contactName'],
            key: 'agentName',
        },
        {
            title: '总价',
            dataIndex: 'billingTotal',
            key: 'billingTotal',
            sorter: (a, b) => a.billingTotal - b.billingTotal,
            render: (text) => `$${text.toFixed(2)}`,
        },
        // {
        //     title: '佣金',
        //     dataIndex: 'commissionAmount',
        //     key: 'commissionAmount',
        //     sorter: (a, b) => a.commissionAmount - b.commissionAmount,
        //     render: (text) => `$${text.toFixed(2)}`,
        // },
        {
            title: '确认/总数',
            dataIndex: 'numOfTotal',
            key: 'numOfTotal',
            render: (text, record) => {
                const { numOfConfirmed, numOfTotal } = record;
                return `${numOfConfirmed}/${numOfTotal}`;
            },
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                return (
                    <Space>
                        <Link to={record.id}>
                            <Button type="link" size="small">
                                查看
                            </Button>
                        </Link>
                        <Button type="link" size="small" danger onClick={() => handleOnDelete(record.id)}>
                            取消
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const handleTableChange = (newPagination, filters, sorter) => {
        //NOTE: leave filters, sorter for local sort
        setReq((prev) => {
            return {
                ...prev,
                page: newPagination.current,
                pageSize: newPagination.pageSize,
            };
        });
    };

    const handleAgencyScopeChange = (e) => {
        setReq((prev) => {
            return { ...prev, agencyScope: e.target.checked, page: 1 }; // Reset to first page when toggling scope
        });
    };

    return (
        <div>
            <Space style={{ marginBottom: 8, display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="link" loading={fetching} onClick={() => fetchData(req)}>
                    刷新
                </Button>
                {GetUserInfo()?.role == AgentRole.Manager && (
                    <Checkbox checked={req.agencyScope} onChange={handleAgencyScopeChange}>
                        显示公司
                    </Checkbox>
                )}
            </Space>
            <Table
                loading={fetching}
                columns={columns}
                dataSource={orders}
                rowKey="id"
                // bordered
                pagination={{
                    current: req.page,
                    pageSize: req.pageSize,
                    total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (num) => `Total ${num} items`,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                onChange={handleTableChange}
            />
        </div>
    );
}

ActivityOrdersTable.propTypes = {};

export default ActivityOrdersTable;
