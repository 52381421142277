import React from 'react';
import PropTypes from 'prop-types';
import Nav from './components/Nav';
import MessageBoard from './components/MessageBoard';
import styles from '@styles/Layout.module.css';
import Console from '@components/Console';
import { Outlet } from 'react-router';
import { useWs } from './contexts/WsContext';

function Layout({ children }) {
    const { isConnected } = useWs();
    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <Nav>
                    <Nav.Account />
                    <Nav.ActivityCart />
                    <Nav.Burger />
                </Nav>
            </div>

            <div className={styles.trailing_container}>
                <aside className={styles.sidebar}>
                    <Console />
                    <div style={{ position: 'absolute', left: 0, bottom: 0 }}>{isConnected ? '🟢' : '🔴'}</div>
                </aside>

                <main className={styles.content}>
                    <MessageBoard />
                    {children}
                    <Outlet />
                </main>
            </div>
        </div>
    );
}

Layout.propTypes = {};

export default Layout;
