import React from 'react';
import PropTypes from 'prop-types';
import MessageBoard from './components/MessageBoard';
import { Outlet } from 'react-router';
import Nav from './components/Nav';

function SimpleLayout({ title = '' }) {
    return (
        <div>
            <Nav title={title}>
                <Nav.CloseIcon />
            </Nav>
            <main className="page_padding_large">
                <MessageBoard />
                <Outlet />
            </main>
        </div>
    );
}

SimpleLayout.propTypes = {
    title: PropTypes.string,
    closeIcon: PropTypes.bool,
};

export default SimpleLayout;
