import React from 'react';
import PropTypes from 'prop-types';
// import { DefaultPricing } from './_ActivityAddForm';

export const DefaultPricing = {
    hasFamilyPlan: false,
    familyPrice: '',
    familyAdultNum: '',
    familyChildNum: '',
    adultPrice: '',
    childPrice: '',
    bundlePrice: '',
    bundlePriced: false,
};

function ActivityPricing({ pricing = DefaultPricing, showFamily = true, showFlamilyPersonNum = false }) {
    return (
        <>
            {!!pricing?.bundlePriced ? (
                `价格: A$${pricing.bundlePrice}`
            ) : (
                <>
                    <div>成人: {!!pricing.adultPrice ? `A$${pricing.adultPrice}` : '-'} </div>
                    <div>儿童: {!!pricing.childPrice ? `A$${pricing.childPrice}` : '-'} </div>
                    {pricing.hasFamilyPlan && showFamily && (
                        <div>
                            <span>家庭: {!!pricing.familyPrice ? `A$${pricing.familyPrice}` : '-'} </span>
                            {showFlamilyPersonNum && (
                                <span style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                                    ({`${pricing.familyAdultNum}成人 ${pricing.familyChildNum}儿童`})
                                </span>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}

ActivityPricing.propTypes = {
    pricing: PropTypes.object.isRequired,
    showFamily: PropTypes.bool,
    showFlamilyPersonNum: PropTypes.bool,
};

export default ActivityPricing;
