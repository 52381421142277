import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/SpinnerInline.module.css';

// colorHex #000000
function SpinnerInline({ active, colorHex, radius }) {
    if (!active) {
        return <></>;
    }

    const colorStyle = {
        borderTop: `0.5rem solid ${colorHex}30`,
        borderRight: `0.5rem solid ${colorHex}30`,
        borderBottom: `0.5rem solid ${colorHex}30`,
        borderLeft: `0.5rem solid ${colorHex}`,

        width: `${radius}`,
        height: `${radius}`,
    };

    return (
        <div className={styles.spinner_inline}>
            <div className={styles.loader} style={colorStyle}></div>
        </div>
    );
}

SpinnerInline.propTypes = {
    active: PropTypes.bool.isRequired,
    colorHex: PropTypes.string,
};

export default SpinnerInline;
