import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '@features/account/accountSlice';
import styles from '@styles/Dashboard.module.css';
import * as config from './config';
import CartPage from './pages/CartPage';
import OrdersPage from './pages/OrdersPage';
import FAQsPage from './pages/FAQsPage';
import FAQPage from './pages/FAQPage';
import { addMessage } from './features/messages/messagesSlice';
import VisaPage from './pages/VisaPage';
import { fetchActivities } from './features/activities/activitiesSlice';
import { fetchAccommodations } from './features/accommodations/accommsSlice';
import { fetchTrips } from './features/trips/tripsSlice';
import { AccommodationEnabled, ActivityEnabled, FlightEnabled, GetToken, GetUserInfo } from './utils/storage';
import { DynamicIcon, DynamicTitle } from './utils/domain';
import { token } from './features/account/accountSlice';
import { fetchCommissionProfile } from './features/commission/commissionSlice';
import Layout from './Layout';
import Test from './pages/Test';
import { DevMode } from './utils/utils';
import SimpleLayout from './SimpleLayout';
import SupportPage from './pages/SupportPage';

const OrderIndex = lazy(() => import(/* vitePrefetch: true */ './pages/order'));
const AirIndex = lazy(() => import(/* vitePrefetch: true */ './pages/air'));
const TripIndex = lazy(() => import(/* vitePrefetch: true */ './pages/trip'));
const AccommodationIndex = lazy(() => import(/* vitePrefetch: true */ './pages/accommodations'));
const ActivityIndex = lazy(() => import(/* vitePrefetch: true */ './pages/activities'));
const AccountIndex = lazy(() => import(/* vitePrefetch: true */ './pages/account'));

function Dashboard(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const loginInfo = GetUserInfo();

    useEffect(() => {
        console.log('Dashboard:', 'email:', loginInfo.email);
        dispatch(fetchActivities());
        dispatch(fetchAccommodations());
        dispatch(fetchTrips());
        dispatch(fetchCommissionProfile());
        dispatch(token());
        // Update icon
        DynamicIcon();
        DynamicTitle();
        return () => {};
    }, []);

    useEffect(() => {
        if (document.title == '') {
            DynamicTitle();
        }
    }, [document.title]);

    //Check JWT token and logout
    useEffect(() => {
        echo();
    }, [location]);

    const echo = async () => {
        // console.log('🦆 Dashboard location');
        const res = await fetch(`${config.apiURL}/echo`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${GetToken()}` },
        });
        // console.log(res.status);
        if (res.status == 401) {
            const jRes = await res.json();
            console.log('log', jRes.error);
            alert(`Please login again.\n(${jRes.error})`); //block
            dispatch(logout());
            navigate(`/?v=${loginInfo.email}`);
        }
        if (res.status == 200) {
            const data = await res.json();
            const buildNum = data.version;
            // console.log('version', buildNum);
            if (buildNum) {
                if (Number(buildNum) > Number(config.BUILD_NUM)) {
                    dispatch(
                        addMessage({
                            type: 'warning',
                            content: ` 请刷新页面。有一个更新(${buildNum})`,
                        })
                    );
                }
            }
        }
    };

    const dynamicHomePage = () => {
        if (FlightEnabled()) {
            return <Navigate to={'/dashboard/air-tickets/git'} />;
        }
        if (ActivityEnabled()) {
            return <Navigate to={'/dashboard/activities/tickets'} />;
        }
        if (AccommodationEnabled()) {
            return <Navigate to={'/dashboard/accommodations/hotels'} />;
        }
        return <div>Opps...</div>;
    };

    return (
        <div className={styles.container}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={dynamicHomePage()} />
                    {FlightEnabled() && <Route path="/air-tickets/*" element={<AirIndex />} />}

                    {AccommodationEnabled() && (
                        <Route path="/accommodations/*" exact element={<AccommodationIndex />} />
                    )}

                    {ActivityEnabled() && (
                        <>
                            <Route path="/activities/*" exact element={<ActivityIndex />} />
                            <Route path="/trips/*" exact element={<TripIndex />} />
                            <Route path="/visa/:cat" exact element={<VisaPage />} />
                            <Route path="/faqs" exact element={<FAQsPage />} />
                            <Route path="/faqs/:id" exact element={<FAQPage />} />
                        </>
                    )}

                    <Route path="/account/*" exact element={<AccountIndex />} />
                    <Route path="/support" exact element={<SupportPage />} />

                    <Route path="/cart" exact element={<CartPage />} />
                    <Route path="/orders" exact element={<OrdersPage />} />
                    <Route path="/orders/:orderID/*" exact element={<OrderIndex />} />

                    <Route path="/test" element={<Test />} />

                    <Route path="/*" element={<div>Page not found</div>} />
                </Route>

                <Route path="/simpleLayout" element={<SimpleLayout title="Test" />}>
                    <Route index element={<Test />} />
                </Route>
            </Routes>
        </div>
    );
}

export default Dashboard;
