import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Burger.module.css';

function Burger({ active = false, onClick = () => {} }) {
    const [burgerClasses, setBurgerClasses] = useState(styles.burger);

    useEffect(() => {
        if (active) {
            setBurgerClasses(`${styles.burger} ${styles.toggle}`);
        } else {
            setBurgerClasses(`${styles.burger}`);
        }
    }, [active]);

    const handleClick = () => {
        console.log('burge clicked');
        onClick();
        toggle();
    };

    const toggle = () => {
        setBurgerClasses((prev) => {
            if (prev.includes(styles.toggle)) {
                return `${styles.burger} `;
            } else {
                return `${styles.burger} ${styles.toggle}`;
            }
        });
    };
    return (
        <div className={burgerClasses} onClick={handleClick}>
            <div className={styles.line1}></div>
            <div className={styles.line2}></div>
            <div className={styles.line3}></div>
        </div>
    );
}

Burger.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Burger;
