import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as config from '@config';
import { GetToken } from '@utils/storage';

export const defaultQueryParams = {
    page: 1,
    pageSize: 10,
    agencyScope: false,
    column: null,
    direction: null,
};

function useActivityOrders(props) {
    const [fetching, setFetching] = useState(false);
    const [orders, setOrders] = useState([]);
    const [total, setTotal] = useState(0);
    const abortControllerRef = useRef(null);

    const fetchOrders = useCallback(async ({ page = 1, pageSize = 10, agencyScope = false, column, direction }) => {
        const offset = (page - 1) * pageSize;
        const limit = pageSize;
        setFetching(true);
        abortControllerRef.current = new AbortController();

        const queryParams = {
            limit: limit,
            offset: offset,
            agencyScope: agencyScope,
            column: column,
            direction: direction,
        };
        const queryString = new URLSearchParams(queryParams).toString();
        // console.log(queryString);
        try {
            const response = await fetch(`${config.apiURL}/orders?${queryString}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetch orders');
            }
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error(data.error);
            }
            if (data.orders) {
                setOrders(data.orders);
            }
            if (data.total) {
                setTotal(data.total);
            }
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching orders:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, orders, total, fetchOrders, abort };
}

useActivityOrders.propTypes = {};

export default useActivityOrders;
