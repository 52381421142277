import React from 'react';
import styles from '@styles/Logo.module.css';
import { WhiteLabelLogoURL, WhiteLabelled } from '../utils/storage';
import * as config from '@config';

function Logo() {
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                {WhiteLabelled() ? (
                    <img src={WhiteLabelLogoURL()} />
                ) : (
                    <img src={`${config.baseURL}/favicon/the_logo.svg`} />
                )}
            </div>
        </div>
    );
}

export default Logo;
