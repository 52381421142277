import React from 'react';
import ActivityOrdersTable from '../components/activity/ActivityOrdersTable';

function OrdersPage() {
    return (
        <div>
            <h3>活动订单</h3>
            <ActivityOrdersTable />
        </div>
    );
}

export default OrdersPage;
