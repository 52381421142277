import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import * as config from '../config';
import styles from '@styles/FAQPage.module.css';
import { Header, Loader } from 'semantic-ui-react';
import BackBar from '../components/BackBar';
import dayjs from 'dayjs';
import { FaFilePdf } from 'react-icons/fa';
import { OpenURLFile } from '../utils/utils';
import { GetToken } from '../utils/storage';

function FAQPage(props) {
    const { id } = useParams();
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});

    const controller = new AbortController();

    useEffect(() => {
        fetchData(id);
        return () => {
            if (fetching) controller.abort();
        };
    }, [id]);

    const fetchData = async (id) => {
        setFetching(true);
        try {
            const res = await axios.get(`${config.adminApiURL}/articles/${id}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (res.data.error) {
                window.alert(res.data.error);
            } else if (res.data.article) {
                setData(res.data.article);
            }
        } catch (error) {
            const errMsg = error.response?.data?.error;
            if (!!errMsg) {
                window.alert(errMsg);
            } else {
                window.alert(error);
            }
        }
        setFetching(false);
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
            <Header as="h2">
                {data.title}
                <Header.Subheader>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                        <span>{data.subtitle}</span>
                        <span>{dayjs(data.updatedAt).format('YYYY-MM-DD')} 更新</span>
                    </div>
                </Header.Subheader>
            </Header>
            <div className={`workaround_description`} dangerouslySetInnerHTML={{ __html: data.content }} />
            <div className={styles.documents_container}>
                {data?.documents?.map((item, index) => {
                    return (
                        <div key={index} className={styles.document_container}>
                            <FaFilePdf className={styles.icon} />
                            <div
                                className={styles.filename}
                                onClick={() => OpenURLFile(item.filename)}
                                title={item.filename}
                            >
                                {item.filename}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

FAQPage.propTypes = {};

export default FAQPage;
