import React from 'react';
import styles from '@styles/CartPage.module.css';
import BackBar from '@components/BackBar';
import CartTable from '@components/CartTable';
import { useNavigate } from 'react-router';
import { useCart } from '../contexts/CartContext';
import { App, Button, Spin } from 'antd';

function CartPage() {
    const { data, fetching, cartToOrder } = useCart();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const handleSubmit = () => {
        cartToOrder()
            .then((data) => {
                const { reply, orderID } = data;
                if (reply) {
                    message?.success(reply);
                }
                if (orderID) {
                    navigate(`/dashboard/orders/${orderID}`, { replace: true });
                } else {
                    navigate(`/dashboard/orders`, { replace: true });
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                message?.error(msg);
            });
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <CartTable cartItems={data.cartItems} />
            <div className={styles.summary_container}>{!!data?.total && `A$${data?.total}`}</div>
            {data?.qty > 0 && (
                <div className={styles.action_container}>
                    <Button type="primary" onClick={handleSubmit}>
                        创建报价单
                    </Button>
                </div>
            )}
            <Spin spinning={fetching} fullscreen />
        </div>
    );
}

export default CartPage;
