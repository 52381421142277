import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import CartTableRowDivider from './CartTableRowDivider';
import CartTableRow from './CartTableRow';

function index({ cartItems }) {
    return (
        <table className={styles.table}>
            <tbody>
                <tr className={styles.header_tr}>
                    <th>产品</th>
                    <th>单价</th>
                    <th>操作</th>
                </tr>
                <CartTableRowDivider />
                {cartItems?.map((item, index) => {
                    return <CartTableRow key={index} item={item} />;
                })}
            </tbody>
        </table>
    );
}

index.propTypes = {
    cartItems: PropTypes.array,
};

export default index;
