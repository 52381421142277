import { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import { GetUserInfo, SaveUserInfo } from '../utils/storage';

function useLoginUser(props) {
    const storedUserInfo = GetUserInfo();
    const [fetching, setFetching] = useState(false);
    const [userInfo, setUserInfo] = useState(storedUserInfo);

    const abortControllerRef = useRef(null);

    const fetchUserInfo = useCallback(async () => {
        setFetching(true);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/user/${storedUserInfo.id}`, {
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetching user info');
            }
            const responseData = await response.json();
            const user = responseData.user;
            if (user) {
                setUserInfo(user);
                SaveUserInfo(user);
            }
            return responseData;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching user info:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, [storedUserInfo.id, config.apiURL]);

    useEffect(() => {
        fetchUserInfo();
    }, [fetchUserInfo]);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { userInfo, fetching, refetch: fetchUserInfo, abort };
}

useLoginUser.propTypes = {};

export default useLoginUser;
