import React from 'react';
import * as config from '@config';

function SupportPage() {
    return (
        <div>
            <h3>客服</h3>
            <div>
                邮箱: <a>{config.SupportEmail}</a>
            </div>
            {/* <div>
                微信ID: <code>xiangkai_gu</code>
            </div> */}
            <br />
        </div>
    );
}

export default SupportPage;
